export default class insertLabelSelectMenu { // JS 语法
  constructor() {
    this.title = '替换标记'
    this.iconSvg = '替换标记'
    this.tag = 'select'
    this.width = 80
  }

  // 下拉框的选项
  getOptions(editor) { // JS 语法
    const options = [
      { value: '', text: '替换标记' , styleForRenderMenuList: { 'display': 'none' } },
      { value: '{{company}}', text: '公司全称' },
      { value: '{{shortName}}', text: '公司简称' },
      { value: '{{website}}', text: '公司网址' },
      { value: '{{SDKTable}}', text: 'sdk列表' },
      { value: '{{thirdPartyTable}}', text: '第三方列表' },
      { value: '{{BBI}}', text: '基本业务信息' },
      { value: '{{EBI}}', text: '扩展业务信息' },
      { value: '{{BCFI}}', text: '分类字段信息显示' },
      { value: '{{BCFI}}', text: '分类字段信息显示' },
      { value: '{{BSCFI}}', text: '敏感信息分类字段显示' },
    ]
    return options
  }

  // 菜单是否需要激活（如选中加粗文本，“加粗”菜单会激活），用不到则返回 false
  isActive(editor) { // JS 语法
    return false
  }

  // 获取菜单执行时的 value ，用不到则返回空 字符串或 false
  getValue(editor) { // JS 语法
    return '' // 匹配 options 其中一个 value
  }

  // 菜单是否需要禁用（如选中 H1 ，“引用”菜单被禁用），用不到则返回 false
  isDisabled(editor) { // JS 语法
    return false
  }

  // 点击菜单时触发的函数
  exec(editor, value) { // JS 语法
    // Select menu ，这个函数不用写，空着即可
    editor.insertText(value)
  }
}

export const insertLabelMenuConf = {
  key: 'insertLabelMenu101', // 定义 menu key ：要保证唯一、不重复（重要）
  factory() {
    // eslint-disable-next-line new-cap
    return new insertLabelSelectMenu() // 把 `YourMenuClass` 替换为你菜单的 class
  },
}
