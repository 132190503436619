<template>
  <div>
    <div>
      <Toolbar
        style="border-bottom: 1px solid #ccc"
        :editor="editor"
        :default-config="toolbarConfig"
        :mode="mode"
      />
      <Editor
        v-model="content.content"
        style="height: 500px;overflow-y: hidden;"
        :style="styleValue"
        :default-config="editorConfig"
        :mode="mode"
        @onCreated="onCreated"
        @onChange="change"
      />
    </div>
  </div>

</template>

<script>
import { Editor, Toolbar } from '@wangeditor/editor-for-vue'
import { Boot } from '@wangeditor/editor'
import { insertLabelMenuConf } from '@core/components/editor-vue/editorManu'
import { success, error } from '@core/utils/utils'

Boot.registerMenu(insertLabelMenuConf)

export default {
  components: { Editor, Toolbar },
  props: {
    content: {
      type: Object,
      default() {
        return { content: '' }
      },
    },
    styleValue: {
      type: Object,
      default() {
        return { height: '400px', overflowY: 'hidden' }
      },
    },
  },
  data() {
    const that = this
    return {
      editor: null,
      htmlStr: '',
      contentImages: [],
      toolbarConfig: {
        toolbarKeys: [
          'header1',
          // 'bold',
          // 'underline',
          // 'italic',
          // 'through',
          // 'code',
          // 'sub',
          // 'sup',
          // 'clearStyle',
          'color',
          'bgColor',
          'fontSize',
          // 'fontFamily',
          'indent',
          // 'delIndent',
          'justifyLeft',
          'justifyRight',
          'justifyCenter',
          'justifyJustify',
          // 'lineHeight',
          'insertImage',
          'deleteImage',
          // "editImage",
          'viewImageLink',
          // "imageWidth30",
          // "imageWidth50",
          // "imageWidth100",
          // 'divider',
          // 'emotion',
          'insertLink',
          // 'editLink',
          // 'unLink',
          // 'viewLink',
          // 'codeBlock',
          // 'blockquote',
          // 'headerSelect',

          // 'header2',
          // 'header3',
          // 'header4',
          // 'header5',
          'todo',
          'redo',
          'undo',
          // "fullScreen",
          'bulletedList',
          'numberedList',
          'insertTable',
          'deleteTable',
          'insertTableRow',
          'deleteTableRow',
          'insertTableCol',
          'deleteTableCol',
          'tableHeader',
          'tableFullWidth',
          // "insertVideo",
          // "uploadVideo",
          'uploadImage',
          // "codeSelectLang"
        ],
      },
      editorConfig: {
        placeholder: '请输入内容...',
        MENU_CONF: {
          uploadImage: {
            server: `${window.g.baseUrl}/website/news/upload_img`,
            // form-data fieldName ，默认值 'wangeditor-uploaded-image'
            fieldName: 'file',
            // 单个文件的最大体积限制，默认为 2M
            // maxFileSize: 1 * 1024 * 1024 * 1024, // 1M
            // 最多可上传几个文件，默认为 100
            maxNumberOfFiles: 10,
            // 选择文件时的类型限制，默认为 ['image/*'] 。如不想限制，则设置为 []
            allowedFileTypes: ['image/*'],
            // 自定义上传参数，例如传递验证的 token 等。参数会被添加到 formData 中，一起上传到服务端。
            meta: {
              token: 'M-Token',
              otherKey: localStorage.getItem('M-Token'),
            },
            // 将 meta 拼接到 url 参数中，默认 false
            metaWithUrl: false,
            // 自定义增加 http  header
            headers: {
              Accept: 'text/x-json',
              'm-token': localStorage.getItem('m-token'),
            },
            // 跨域是否传递 cookie ，默认为 false
            withCredentials: true,
            // 超时时间，默认为 10 秒
            timeout: 5 * 1000, // 5 秒
            // 单个文件上传成功之后
            onSuccess(file, res) {

            },
            onError(file) {
              if (file.size > 2 * 1024 * 1024) {
                error('单张图片大小不能超过2M')
              }
              console.log(file.size)
            },
            customInsert(res, insertFn) {
              insertFn(res.data.accessPath, '', '')
              that.contentImages.push(res.data.savePath)
              console.log(that.contentImages)
            },
          },
        },
      },
      mode: 'default', // or 'simple'
    }
  },
  mounted() {
    this.toolbarConfig.insertKeys = {
      title: '替换标签',
      index: 16, // 插入的位置，基于当前的 toolbarKeys
      keys: ['insertLabelMenu101'],
    }
    this.toolbarConfig.excludeKeys = [
      'group-video',
      'fullScreen',
    ]
  },
  beforeDestroy() {
    const { editor } = this
    if (editor == null) return
    editor.destroy() // 组件销毁时，及时销毁编辑器
  },
  methods: {
    onCreated(editor) {
      this.editor = Object.seal(editor) // 一定要用 Object.seal() ，否则会报错
    },
    // eslint-disable-next-line no-unused-vars
    change(editor) {
      // this.$emit('update:content', this.htmlStr)

    },
  },
}
</script>
<style>
    .w-e-text-container table td, .w-e-text-container table th {
        border: 1px solid var(--w-e-textarea-border-color);
        padding: 3px 5px;
    }
</style>
<style src="../../../../node_modules/@wangeditor/editor/dist/css/style.css"></style>
